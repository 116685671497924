import { observable, action } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';
import { AcDownloadFile } from '@utils/ac-download-file.js';

export class DocumentsStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  all = [];

  @observable
  single = [];

  @observable
  loading = false;

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  fetchAll() {
    this.setLoading(true);

    return this.store.api.documents
      .all()
      .then((response) => {
        this.all = response.data;
        this.setLoading(false);
        return response;
      })
      .catch((error) => {
        this.store.toasters.add({
          variant: 'error',
          title: `Ophalen van documenten is mislukt`,
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  download(id, name, path, mimetype) {
    this.setLoading(true);

    return this.store.api.documents
      .single(id)
      .then((response) => {
        const filename = name;
        let path_arr = path.split('/')[2];
        let mime = mimetype ? mimetype : path_arr.split('.');
        if (!mimetype) mime = mimetype[mimetype.length - 1];

        switch (mimetype) {
          case 'pdf':
            mimetype = 'application/pdf';
            break;

          case 'xls':
            mimetype = 'application/xls';
            break;

          case 'application/doc':
          case 'docx':
            mimetype = 'application/docx';
            break;

          case 'powerpoint':
            mimetype = 'application/ppt';
            break;

          default:
        }

        AcDownloadFile(response.data, filename, mimetype);

        this.store.toasters.add({
          variant: 'success',
          title: `Downloaden van document '${name}' is gelukt`,
        });

        this.setLoading(false);
        return response;
      })
      .catch((error) => {
        this.store.toasters.add({
          variant: 'error',
          title: `Downloaden van document '${name}' is mislukt`,
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async fetch(id) {
    let data;

    if (!id) {
      data = await this.fetchAll();
    } else if (id) {
      data = await this.fetchSingle(id);
    }

    return data;
  }
}

export default DocumentsStore;
