import { observable, computed, action, reaction } from 'mobx';
import i18n from '@config/i18n';

export class SettingsStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  language = localStorage.getItem('rea_locale')
    ? localStorage.getItem('rea_locale')
    : 'nl';

  @observable
  languages = ['en', 'nl'];

  @observable
  loading = false;

  @computed
  get currentLanguage() {
    return this.language;
  }

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  async setLanguage(language) {
    if (!language || language === null) language = 'nl';

    this.setLoading(true);

    if (this.store.auth.isAuthorized) {
      await this.store.api.locale.set(language);
      await this.store.auth.whoami();
    }

    // Set & store the locale setting
    localStorage.setItem('rea_locale', language);

    return (this.language = language);
  }

  changeLanguageReaction = reaction(
    () => this.language,
    async language => {
      await i18n.changeLanguage(language);
      this.setLoading(false);
    }
  );
}

export default SettingsStore;
