import { observable, action } from 'mobx';

export class UiStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  navigation = {
    visible: false,
  };

  @observable
  lightbox = {
    visible: false,
    collection: [],
    target: 0,
  };

  @observable
  user_navigation = {
    visible: false,
  };

  @action
  openLightbox = () => {
    this.lightbox.visible = true;
  };

  @action
  closeLightbox = () => {
    this.lightbox.visible = false;
  };

  @action
  setLightboxTarget = target => {
    return new Promise(resolve => {
      this.lightbox.target = target;
      resolve();
    });
  };

  @action
  setLightboxCollection = collection => {
    return new Promise(resolve => {
      this.lightbox.collection = collection;
      resolve();
    });
  };

  @action
  setState = (target, property, value) => {
    if (!target) return;
    if (!property) return;
    if (typeof value === 'undefined' || value === null) return;

    return new Promise(resolve => {
      this[target][property] = value;
      resolve();
    });
  };
}

export default UiStore;
