// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';

export class ProfileStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  account = {};

  @observable
  accounts = [];

  @observable
  loading = false;

  @computed
  get current_profile() {
    return this.account && { ...this.account };
  }

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  setAccount(account) {
    this.setLoading(true);

    return this.store.api.profile.account
      .set(account)
      .then(response => {
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title: 'Wisselen van account is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  getAccount() {
    return this.store.api.profile.account.get().then(response => {
      this.account = response;
    });
  }

  @action
  whoami() {
    return this.store.api.auth.whoami();
  }
}

export default ProfileStore;
