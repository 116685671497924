// Imports => MOBX
import { observable, action } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';

export class KPIsStore {
  constructor(store) {
    this.store = store;
  }

  all = [];

  @observable
  loading = true;

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  fetchAll() {
    this.setLoading(true);

    return this.store.api.kpis
      .all()
      .then(response => {
        let result = {};

        Object.keys(response).forEach(item => {
          result[item] = Object.keys(response[item]).map(unit => {
            return response[item][unit];
          });
        });

        this.all = result;
        this.setLoading(false);
        return result;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: "Ophalen van KPI's is mislukt",
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async fetch() {
    let data;

    data = await this.fetchAll();

    return data;
  }
}

export default KPIsStore;
