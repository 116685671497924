// Imports => MOBX
import { observable, action, computed, toJS } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';

export class ProjectsStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  all = [];

  @observable
  filtered = [];

  @observable
  single = [];

  @observable
  fases = [];

  @observable
  updates = [];

  @observable
  loading = true;

  @computed
  get current_projects() {
    return toJS(this.all);
  }

  @computed
  get current_project() {
    return toJS(this.single);
  }

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  fetchFases() {
    return this.store.api.projects
      .fases()
      .then(response => {
        this.fases = response;
        return response;
      })
      .catch(error => {
        throw error;
      });
  }

  @action
  fetchAll() {
    this.setLoading(true);

    return this.store.api.projects
      .all()
      .then(response => {
        this.all = response;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Ophalen van projecten is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  fetchSingle(id, update) {
    this.setLoading(true);

    return this.store.api.projects
      .single(id, update)
      .then(response => {
        this.single = response;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Ophalen van project is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  fetchUpdates(id, update) {
    this.setLoading(true);
    return this.store.api.projects
      .updates(id, update)
      .then(response => {
        this.updates = response;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Ophalen van project updates is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  fetchByStatus(status) {
    if (!status) return;

    this.setLoading(true);

    return this.store.api.projects
      .status(status)
      .then(response => {
        this.all = response;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Ophalen van projecten is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async fetch(id, update) {
    let data;

    if (!id) {
      data = await this.fetchAll();
    } else if (id) {
      data = await this.fetchSingle(id, update);
    }

    return data;
  }

  @action
  async getFilteredProjects(status) {
    this.setLoading(true);

    this.filtered = this.all.filter(item => item.status === status);

    this.setLoading(false);

    return this.filtered;
  }
}

export default ProjectsStore;
