// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message';
import AcFormatErrorCode from '@utils/ac-format-error-code';
import {
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcClearState,
} from '@utils/ac-storage';

let app = {};

export class AuthStore {
  constructor(store) {
    AcAutoLoad(this, 'user');
    AcAutoLoad(this, 'impersonated');
    AcAutoLoad(this, 'access_token');
    AcAutoLoad(this, 'expires_at');
    AcAutoLoad(this, 'session');
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  user = null;

  @observable
  impersonated = null;

  @observable
  session = {
    user: null,
    expires_at: false,
    access_token: false,
    impersonated: null,
  };

  @observable
  loading = false;

  @computed
  get current_access_token() {
    return this.session.access_token;
  }

  @computed
  get current_expires_at() {
    return this.session.expires_at;
  }

  @computed
  get current_user() {
    return this.session.user;
  }

  @computed
  get isImpersonating() {
    return this.session.impersonated;
  }

  @computed
  get isAuthorized() {
    let authorized = false;

    let session = {
      access_token: this.current_access_token,
      expires_at: this.current_expires_at,
      user: this.current_user,
      impersonated: this.isImpersonating,
    };

    if (session.access_token && session.expires_at && session.user) {
      let expiresAt = JSON.parse(session.expires_at);
      let now = new Date().getTime();

      // console.group('[Store: Auth] => computed.isAuthorized');
      // console.log('Session: ', session);
      // console.log('Now: ', now, new Date(now));
      // console.log('Expires at: ', expiresAt, new Date(expiresAt));
      // console.log('User: ', session.user);

      authorized = session.user && session.access_token && now < expiresAt;

      // console.log('Is Authorized: ', authorized);
      // console.groupEnd();
    }

    return authorized;
  }

  @computed
  get currentUser() {
    return this.user;
  }

  // @action
  // setImpersonated = data => {
  //   this.impersonated = data;
  //   this.impersonated.expires = this.user.expires;

  //   AcSaveState('impersonated', this.impersonated);
  // };

  @action
  setLoading = state => {
    this.loading = state ? state : false;
  };

  @action
  clearAuthentication = () => {
    this.clearSession();
  };

  @action
  handleAuthentication = result => {
    this.setSession(result);

    if (
      result.language &&
      result.language !== null &&
      result.language !== app.store.settings.currentLanguage
    ) {
      app.store.settings.setLanguage(result.language);
    }
  };

  @action
  setUser = result => {
    this.user = result;
    this.session.user = result;

    AcSaveState('user', result);
    AcSaveState('session', this.session);
  };

  @action
  setImpersonated = result => {
    this.impersonated = result;
    this.session.impersonated = result;

    AcSaveState('impersonated', result);
    AcSaveState('session', this.session);
  };

  @action
  setSession = result => {
    let now = new Date();
    let expiresAt = now.setHours(now.getHours() + 8);

    // Set the expires-at stamp that the access token will expire at
    AcSaveState('expires_at', expiresAt);
    this.session.expires_at = expiresAt;

    // Set the access token
    AcSaveState('access_token', result.access_token);
    this.session.access_token = result.access_token;

    AcSaveState('user', result);
    this.user = result;
    this.session.user = result;

    AcSaveState('impersonated', result.impersonated);
    this.session.impersonated = result.impersonated;

    AcSaveState('session', this.session);

    return true;
  };

  @action
  clearSession = () => {
    // Clear access token and expires-at stamp from local storage
    this.session.access_token = false;

    this.session.expires_at = false;

    this.session.impersonated = null;

    this.impersonated = null;

    this.session.user = null;

    this.user = null;

    AcClearState();
  };

  @action
  login = credentials => {
    localStorage.clear();
    this.setLoading(true);

    return app.store.api.auth
      .login(credentials.username, credentials.password)
      .then(response => {
        this.handleAuthentication(response);
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);

        app.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title:
            'De combinatie van uw gebruikersnaam en wachtwoord is niet juist.',
          // description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  impersonateUser = (user_id, signature, expires) => {
    localStorage.clear();
    this.setLoading(true);

    return app.store.api.auth
      .impersonate(user_id, signature, expires)
      .then(response => {
        this.handleAuthentication(response);
        this.setImpersonated(response);
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);
        throw error;
      });
  };

  @action
  logout = () => {
    this.setLoading(true);

    return app.store.api.auth
      .logout()
      .then(response => {
        this.clearAuthentication(response);
        this.impersonated = null;
        this.setLoading(false);

        return response;
      })
      .catch(error => {
        this.clearAuthentication(error);
        this.setLoading(false);

        throw error;
      });
  };

  @action
  forgot = credentials => {
    this.setLoading(true);

    return app.store.api.auth
      .forgot(credentials.username)
      .then(response => {
        this.setLoading(false);

        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);

        app.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title: 'Nieuw wachtwoord aanvraag is mislukt',
          // description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  reset = credentials => {
    this.setLoading(true);

    return app.store.api.auth
      .reset(credentials.password, credentials.reset_token)
      .then(response => {
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);

        app.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title: 'Nieuw wachtwoord instellen is mislukt',
          // description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  register = credentials => {
    this.setLoading(true);

    return app.store.api.auth
      .register(
        credentials.username,
        credentials.password,
        credentials.invite_token
      )
      .then(response => {
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);

        if (error.response.status === 422) {
          app.store.toasters.add({
            variant: 'error',
            delay: 5000,
            title: Object.values(error.response.data.errors)[0],
            code: AcFormatErrorCode(error),
          });
        } else {
          app.store.toasters.add({
            variant: 'error',
            delay: 5000,
            title:
              'De combinatie van uw gebruikersnaam en wachtwoord is niet juist.',
            // description:
            //   'De combinatie van uw gebruikersnaam en wachtwoord is niet juist.',
            code: AcFormatErrorCode(error),
          });
        }

        this.setLoading(false);
        throw error;
      });
  };

  @action
  whoami = () => {
    this.setLoading(true);

    return app.store.api.auth
      .whoami()
      .then(response => {
        this.setUser({ ...this.current_user, ...response });

        const lang = localStorage.getItem('rea_locale');

        if (!lang) {
          if (
            response.language &&
            response.language !== null &&
            response.language !== app.store.settings.currentLanguage
          ) {
            app.store.settings.setLanguage(response.language);
          }
        }

        this.setLoading(false);
        // app.store.profile.whoami();
        return response;
      })
      .catch(error => {
        this.setLoading(false);
        this.clearAuthentication(error);

        throw error;
      });
  };
}

export default AuthStore;
