import { observable, action } from 'mobx';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';

export class ProjectIntroductionsStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  all = [];

  @observable
  single = [];

  @observable
  loading = false;

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  fetchAll() {
    this.setLoading(true);

    return this.store.api.project_introductions
      .all()
      .then(response => {
        this.all = response.data;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: `Ophalen van veel gestelde vragen is mislukt`,
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  fetchSingle(id) {
    this.setLoading(true);

    return this.store.api.project_introductions
      .single(id)
      .then(response => {
        this.single = response.data;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: `Ophalen van veel gestelde vraag is mislukt`,
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async fetch(id) {
    let data;

    if (!id) {
      data = await this.fetchAll();
    } else if (id) {
      data = await this.fetchSingle(id);
    }

    return data;
  }
}

export default ProjectIntroductionsStore;
