import { observable, action } from 'mobx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';

const variants = ['default', 'info', 'success', 'warning', 'error'];
let interval = null;

export class ToastersStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  queue = [];

  @action
  get() {
    return this.queue;
  }

  @action
  add(options) {
    let _stamp = new Date();
    let _delay = options.delay ? options.delay : 1000 * 10;
    let _time = _stamp.getTime();

    /*
			Options: {
				variant: '',
				title: '',
				description: '',
				delay: 0,
			}
		*/

    const toast = {
      ...options,
      delay: _delay,
      time: _time,
      expires: _time + _delay + 1500,
      id: AcUUID(),
    };

    this.queue.push(toast);
  }

  @action
  remove(id) {
    const collection = this.queue;

    const new_collection = collection.filter(item => item.id !== id);

    this.queue = new_collection;
  }

  @action
  updateQueue() {
    if (interval) clearInterval(interval);
    interval = setInterval(() => {
      let n = [1, 2, 3][Math.floor(Math.random() * [1, 2, 3].length)];
      let obj = {
        variant: variants[Math.floor(Math.random() * variants.length)],
        title: 'Toaster message',
      };
      obj.description =
        n % 3 === 0
          ? 'Lorem ipsum dolor sit amet'
          : n % 2 === 0
          ? 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus nibh.'
          : false;
      this.add(obj);
    }, 3500);
  }
}

export default ToastersStore;
