import API from '@api';

import AuthStore from '@stores/auth.store.js';
import ProfileStore from '@stores/profile.store.js';
import SettingsStore from '@stores/settings.store.js';

import UiStore from '@stores/ui.store.js';
import ToastersStore from '@stores/toasters.store.js';

import KPIsStore from '@stores/kpis.store.js';
import ParticipationStore from '@stores/participation.store.js';
import ProjectsStore from '@stores/projects.store.js';
import NewsStore from '@stores/news.store.js';
import FaqStore from '@stores/faq.store.js';
import DocumentsStore from '@stores/documents.store.js';
import ProjectIntroductionsStore from '@stores/project-introductions.store.js';
import OverviewProjectUpdatesStore from '@stores/overview-project-updates.store.js';

class Store {
  constructor(config) {
    this.config = config;

    this.api = new API(config, this);

    this.auth = new AuthStore(this);
    this.profile = new ProfileStore(this);
    this.settings = new SettingsStore(this);

    this.ui = new UiStore(this);
    this.toasters = new ToastersStore(this);

    this.kpis = new KPIsStore(this);
    this.participation = new ParticipationStore(this);
    this.projects = new ProjectsStore(this);
    this.news = new NewsStore(this);

    this.faq = new FaqStore(this);

    this.documents = new DocumentsStore(this);

    this.projectIntroductions = new ProjectIntroductionsStore(this);
    this.overviewProjectUpdates = new OverviewProjectUpdatesStore(this);
  }
}

export default Store;
