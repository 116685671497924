// Imports => Moment
import moment from 'moment';
import IBAN from 'iban';

// Imports => Utils
import AcUUID from '@utils/ac-uuid';
import AcCurrencySymbol from '@utils/ac-currency-symbol';
import AcFormatDate from '@utils/ac-format-date';
import AcFormatPhonenumber from '@utils/ac-format-phonenumber';
import AcIsObject from '@utils/ac-isobject';
import AcSanitize from '@utils/ac-sanitize';

// Imports => Assets
import REALIANCEHero from '@assets/images/hero/realiance-hero-bw-2x.jpg';

// Imports => Constants
import Attributes, {
  ATTRIBUTES_COMPLETED as CompletedAttributes,
} from '@constants/project.attributes.constants.js';

class Project {
  constructor(project, properties, store) {
    this.store = store;

    this.project = project;
    this.properties = properties;

    this.enrichProject = this.enrichProject.bind(this);

    this.addApplicationStatus = this.addApplicationStatus.bind(this);
    this.addAttributes = this.addAttributes.bind(this);
    this.addCompletedAttributes = this.addCompletedAttributes.bind(this);
    this.addContacts = this.addContacts.bind(this);
    this.addDescription = this.addDescription.bind(this);
    this.addDocuments = this.addDocuments.bind(this);
    this.addKeyFigures = this.addKeyFigures.bind(this);
    this.addKPIs = this.addKPIs.bind(this);
    this.addImages = this.addImages.bind(this);
    this.addCover = this.addCover.bind(this);
    this.addStatus = this.addStatus.bind(this);
    this.addTransactions = this.addTransactions.bind(this);
    this.addUpdates = this.addUpdates.bind(this);

    const enrichedProject = this.enrichProject();

    return enrichedProject;
  }

  enrichProject() {
    if (this.properties.application) this.addApplicationStatus();

    if (this.properties.attributes) this.addAttributes();

    if (this.properties.contacts) this.addContacts();
    if (this.properties.completedAttributes) this.addCompletedAttributes();

    if (this.properties.description) this.addDescription();

    if (this.properties.documents) this.addDocuments();

    if (this.properties.images) {
      this.addCover();
      this.addImages(this.project.project_images);
    }

    if (this.properties.keyfigures) this.addKeyFigures();

    if (this.properties.kpis) this.addKPIs();

    if (this.properties.status) this.addStatus();

    if (this.properties.transactions) this.addTransactions();

    if (this.properties.updates) this.addUpdates();

    return this.project;
  }

  addApplicationStatus() {
    let result = [];

    result = [
      {
        id: AcUUID(),
        title: 'Inschrijving',
        current: false,
        finished: true,
      },
      {
        id: AcUUID(),
        title: 'Kopie legitimatiebewijs',
        current: false,
        finished: true,
      },
      {
        id: AcUUID(),
        title: 'Ondertekenen akten',
        current: true,
        finished: false,
        comment: 'Wij sturen u documentatie om te ondertekenen.',
      },
      {
        id: AcUUID(),
        title: 'Relatie onderzoek',
        current: false,
        finished: false,
      },
      {
        id: AcUUID(),
        title: 'Betaling aan ...',
        current: false,
        finished: false,
      },
      {
        id: AcUUID(),
        title: 'Bewijs van inschrijving',
        current: false,
        finished: false,
      },
    ];

    this.project.application = result;
  }

  addAttributes() {
    let result = [];

    let attributes = Attributes.filter(
      (attr) => this.properties.attributes.indexOf(attr._key) !== -1
    );

    const locale = this.project.region === 'USA' ? 'en-US' : 'nl-NL';
    const symbol = AcCurrencySymbol(locale === 'en-US' ? 'USD' : 'EUR');

    attributes.forEach((attr) => {
      let object = {
        id: AcUUID(),
        _key: attr._key,
        label: attr._key === 'roi_expected' ? attr.label(locale) : attr.label,
        locale: locale,
        value:
          (attr.type === 'currency' && this.project[attr._key] !== undefined) ||
          this.project[attr._key]
            ? this.project[attr._key]
            : '-',
        type: attr.type,
      };

      if (attr.format && object.value !== '-') {
        let value = object.value;

        if (object.type === 'currency') {
          value = Math.round(parseFloat(value)).toLocaleString('nl-NL', {
            style: 'decimal',
            minimumFractionDigits: 0,
          });
        }

        object.value = attr.format(value, symbol);
      }
      result.push(object);
    });

    this.project.attributes = result;
  }

  addCompletedAttributes() {
    let result = [];

    let completedAttributes = CompletedAttributes.filter(
      (attr) => this.properties.completedAttributes.indexOf(attr._key) !== -1
    );

    const locale = this.project.region === 'USA' ? 'en-US' : 'nl-NL';
    const symbol = AcCurrencySymbol(locale === 'en-US' ? 'USD' : 'EUR');

    completedAttributes.forEach((attr) => {
      let object = {
        id: AcUUID(),
        _key: attr._key,
        label:
          attr._key === 'gross_total_return' ||
          attr._key === 'gross_return_roi_per_year'
            ? attr.label(locale)
            : attr.label,
        locale: (locale) => locale,
        value:
          (attr.type === 'currency' && this.project[attr._key] !== undefined) ||
          this.project[attr._key]
            ? this.project[attr._key]
            : '-',
        type: attr.type,
      };
      if (attr.format && object.value !== '-') {
        let value = object.value;

        if (object.type === 'currency') {
          value = Math.round(parseFloat(value)).toLocaleString('nl-NL', {
            style: 'decimal',
            minimumFractionDigits: 0,
          });
        }

        object.value = attr.format(value, symbol);
      }
      result.push(object);
    });

    this.project.completedAttributes = result;
  }

  addContacts() {
    let result = null;

    if (this.project.project_contact) {
      const _contact = this.project.project_contact;
      const _phonenumber = AcSanitize(_contact.phonenumber);
      result = {
        ..._contact,
        phonenumber: {
          value: _contact.phonenumber,
          pretty: AcFormatPhonenumber(_phonenumber, 'nl'),
          sanitized: _phonenumber,
        },
      };
    }

    this.project.contact = result;
  }

  addCover() {
    let result = null;
    let image = null;

    if (
      this.project.project_description &&
      this.project.project_description.image &&
      this.project.project_description.image !== []
    ) {
      image = this.project.project_description.image;
    } else if (
      this.project.project_images &&
      this.project.project_images.length > 0
    ) {
      image = this.project.project_images[0];
    }

    if (image && image.responsive && image.responsive.lg) {
      result = {
        ...image,
        index: 1,
        id: AcUUID(),
        label: image.name,
        url: image.url,
      };
    } else {
      result = {
        index: 1,
        id: AcUUID(),
        label: '',
        url: REALIANCEHero,
        responsive: {
          xs: REALIANCEHero,
          sm: REALIANCEHero,
          md: REALIANCEHero,
          lg: REALIANCEHero,
          xl: REALIANCEHero,
        },
      };
    }

    this.project.cover = result;
  }

  addDescription() {}

  addDocuments(collection, get) {
    let result = [];

    if (!collection || typeof collection === 'undefined' || collection === null) {
      collection = this.project.project_files;
    }

    if (!collection || collection.length === 0) {
      result = false;

      return;
    }

    result = collection.map((file, x) => {
      let item = {
        id: AcUUID(),
        type: file.mime_type,
        index: x,
        label: file.name,
        url: file.url,
        sizes: file.responsive,
      };

      return item;
    });

    if (!get) this.project.documents = result;

    return result;
  }

  addImages(collection, get) {
    let result = [];

    if (!collection || typeof collection === 'undefined' || collection === null) {
      collection = this.project.project_images || [];
    }

    result = collection.map((image, x) => {
      let item = {
        ...image,
        index: x,
        id: image.id || AcUUID(),
        label: image.name,
        url: image.url,
      };

      return item;
    });

    if (!get) this.project.images = result;

    return result;
  }

  addKeyFigures() {
    let result = [];

    if (this.project.keydata && this.project.keydata.length > 0) {
      result = this.project.keydata.map((item) => {
        return {
          ...item,
          value: item.data,
          label: item.key,
        };
      });
    }

    this.project.key_figures = result;
  }

  addKPIs() {
    let result = [];

    if (this.project.project_kpis && AcIsObject(this.project.project_kpis)) {
      result = Object.keys(this.project.project_kpis).map((item) => {
        return this.project.project_kpis[item];
      });
    }

    this.project.kpis = result;
  }

  addStatus() {
    let result = [];

    if (this.project.project_fases && this.project.project_fases.length > 0) {
      result = this.project.project_fases.map((item, n) => {
        const fase = {
          ...item,
          id: item.id,
          title: item.name,
          subtitle: null,
          comment: false,
          current: item.active === 1,
          finished: item.completed === 1,
          size: 'small',
        };

        return fase;
      });
    }

    this.project.status_updates = result;
  }

  addTransactions() {
    let result = [];

    const collection = this.project.project_benefits;

    if (collection && collection.length > 0) {
      // Sort results (ASC)
      result = collection.sort((a, b) => {
        return moment
          .utc(a.date, 'DD-MM-YYYY')
          .diff(moment.utc(b.date, 'DD-MM-YYYY'));
      });

      result = result.map((item) => {
        item.sign =
          parseFloat(item.amount) > 0 ? '+' : parseFloat(item.amount) < 0 ? '-' : '';
        item.symbol = AcCurrencySymbol(item.currency);
        item.amount_with_taxes = parseFloat(
          item.amount - item.withheld_taxes
        ).toLocaleString('nl-NL', {
          style: 'decimal',
          minimumFractionDigits: 2,
        });
        item.amount = parseFloat(item.amount).toLocaleString('nl-NL', {
          style: 'decimal',
          minimumFractionDigits: 2,
        });

        item.date = AcFormatDate(item.date, 'DD-MM-YYYY', 'DD MMMM YYYY');
        item.to = IBAN.isValid(item.to) ? IBAN.printFormat(item.to) : item.to;

        return item;
      });
    }

    this.project.transactions = result;
  }

  addUpdates() {
    let result = [];

    if (this.project.project_updates && this.project.project_updates.length) {
      result = this.project.project_updates.map((update) => {
        update.kpis = this.formatKPIs(update.kpis, 'small');
        return update;
      });
    }

    this.project.updates = result;
  }

  formatKPIs(collection, size) {
    if (!collection) return collection;

    return collection.map((item) => {
      item.size = size ? size : 'default';
      return item;
    });
  }

  addActions() {
    // let result = [];
  }
}

export default Project;
