import { observable, action } from 'mobx';
import set from 'lodash/set';
import DATA from '@data/data.participation.js';

// Imports => Utilities
import AcFormatErrorMessage from '@utils/ac-format-error-message.js';
import AcFormatErrorCode from '@utils/ac-format-error-code.js';

export class ParticipationStore {
  constructor(store) {
    this.store = store;
  }

  @observable
  all = [];

  @observable
  single = null;

  @observable
  data = DATA;

  @observable
  loading = false;

  @action
  setLoading(state) {
    this.loading = state ? state : false;
  }

  @action
  reset() {
    this.all = [];
    this.single = null;
    this.loading = false;
    this.data = DATA;
  }

  @action
  set(path, value) {
    const clone = set(this.data, path, value);

    this.data = clone;
  }

  @action
  fetchAll() {
    this.setLoading(true);

    return this.store.api.participation
      .all()
      .then(response => {
        this.all = response.data;
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Ophalen van inschrijvingen is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async fetch(id) {
    await this.fetchAll();

    let data = this.all;

    if (id) {
      data = data.filter(item => parseInt(item.id, 10) === parseInt(id, 10));
      this.single = data && data.length > 0 ? data[0] : [];
    }

    return data;
  }

  @action
  save() {
    this.setLoading(true);

    const parameters = {
      project_id: this.data.project_id,
      portal_user_id: this.data.contact.id,
      account_id: this.data.account,
    };

    return this.store.api.participation
      .save(
        parameters.project_id,
        parameters.portal_user_id,
        parameters.account_id
      )
      .then(response => {
        this.store.toasters.add({
          variant: 'success',
          title: 'Inschrijving is opgeslagen',
        });
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Versturen van inschrijving is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }

  @action
  download(url) {
    this.setLoading(true);

    return this.store.api.participation
      .download(url)
      .then(response => {
        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.store.toasters.add({
          variant: 'error',
          title: 'Download is mislukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        this.setLoading(false);
        throw error;
      });
  }
}

export default ParticipationStore;
